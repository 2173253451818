import { createApp } from 'vue';
import mitt from 'mitt';
import App from './App.vue';
import router from './router';
import store from './store';
import VueInputMask from './helpers/vue-inputmask';
import SvgIcon from './components/SvgIcon.vue';
// eslint-disable-next-line import/extensions
import directives from './directives';

const emitter = mitt();

const app = createApp(App);

directives.forEach((directive) => {
    app.directive(directive.name, directive);
});

app.config.globalProperties.emitter = emitter;
app.use(store)
    .use(router)
    .use(VueInputMask)
    .component('SvgIcon', SvgIcon)
    .mount('#app');

router.beforeEach((to, from, next) => {
    if (store.state && store.state.auth.user.first_redirect) {
        if ((to.name !== 'partner-first')) {
            next({ path: '/partner/first/' });
        } else {
            next();
        }
    } else if (store.state && store.state.auth.user.rule_redirect) {
        if ((to.name !== 'partner-entry-rules')) {
            next({ path: '/partner/entry-rules/' });
        } else {
            next();
        }
    } else if (store.state && store.state.auth.user.offer_redirect) {
        if (store.state.auth.user.manager.owner
            && (to.name !== 'partner-offer-detail')) {
            next({ path: `/partner/offers/${store.state.auth.user.offer_redirect}` });
        } else {
            next();
        }
    } else if (store.state && store.state.auth.user.bill_redirect) {
        if (store.state.auth.user.manager.owner
            && (to.name !== 'partner-buh-act')) {
            next({ path: `/partner/buh/${store.state.auth.user.bill_redirect}/act` });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default app;
