import { parse, stringify } from 'qs';
import store from '@/store/index';
import { fileHeader, jsonHeader } from '@/helpers/axiosHeaders';
import axios from 'axios';
import router from '@/router';

const controller = new AbortController();
const axiosInstance = axios.create({
    withCredentials: true,
    paramsSerializer: {
        encode: parse,
        serialize: stringify,
    },
    signal: controller.signal,
});

export default {
    get(path, params) {
        if (!this.currentAbortController) {
            this.currentAbortController = null;
        }
        if (this.currentAbortController) {
            this.currentAbortController.abort();
        }

        this.currentAbortController = new AbortController();
        const signal = this.currentAbortController.signal;

        return new Promise((resolve, reject) => {
            let pathToApi = process.env.VUE_APP_API_PATH;
            if (path === '/sanctum/csrf-cookie') {
                pathToApi = `${process.env.VUE_APP_PATH_BACK}/public`;
            }
            axiosInstance.get(`${pathToApi}${path}`, {
                ...this.axiosConfig(params),
                signal,
            })
                .then((response) => {
                    this.currentAbortController = null;

                    const noRedirect = params && params.no_redirect === true;
                    if (path.indexOf('/counter/') === -1 && !noRedirect) {
                        this.redirectHandler(response);
                    }
                    if (path === '/orders/canceled' || path === '/orders/finished' || path.indexOf('/partner_orders/') === 0) {
                        resolve(response);
                    } else {
                        resolve(response.data.data ? response.data.data : response.data);
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        reject(error.response ? error.response.data.errorCode : {});
                    }
                });
        });
    },
    getBlob(path, params) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${process.env.VUE_APP_API_PATH}${path}`, this.axiosConfigBlob(params))
                .then((response) => {
                    this.redirectHandler(response);
                    resolve(response.data.data ? response.data.data : response.data);
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data ? response.response.data.errorCode : '0');
                    });
                });
        });
    },
    post(path, params, data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`${process.env.VUE_APP_API_PATH}${path}`, data, this.axiosConfig(params))
                .then((response) => {
                    this.redirectHandler(response);
                    resolve(response.data.data ? response.data.data : response.data);
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        if (response.response.status === 422) {
                            //  if validation form error
                            reject(response.response.data);
                        } else {
                            reject(response.response.data.errorCode);
                        }
                    });
                });
        });
    },
    postFile(path, params, data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`${process.env.VUE_APP_API_PATH}${path}`, data, this.axiosConfigFile(params))
                .then((response) => {
                    this.redirectHandler(response);
                    resolve(response.data.data ? response.data.data : response.data);
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },
    put(path, params, data) {
        return new Promise((resolve, reject) => {
            let form = data;
            if (data instanceof FormData) {
                data.append('_method', 'PUT');
            } else {
                form = new FormData();
                for (const key in data) {
                    form.append(key, data[key]);
                }
                form.append('_method', 'PUT');
            }
            axiosInstance.post(`${process.env.VUE_APP_API_PATH}${path}`, form, this.axiosConfig(params))
                .then((response) => {
                    this.redirectHandler(response);
                    resolve(response.data.data ? response.data.data : response.data);
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        if (response.response.status === 422) {
                            //  if validation form error
                            reject(response.response.data);
                        } else {
                            reject(response.response.data.errorCode);
                        }
                    });
                });
        });
    },
    delete(path, params) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`${process.env.VUE_APP_API_PATH}${path}`, this.axiosConfig(params))
                .then((response) => {
                    this.redirectHandler(response);
                    resolve(response.data.data ? response.data.data : response.data);
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },
    axiosConfig(params) {
        return {
            withCredentials: true,
            params: params || {},
            headers: jsonHeader,
        };
    },
    axiosConfigFile(params) {
        return {
            params: params || {},
            headers: fileHeader,
        };
    },
    axiosConfigBlob(params) {
        return {
            params: params || {},
            headers: fileHeader,
            responseType: 'blob',
        };
    },
    formData(data) {
        let form = data;
        if (!(form instanceof FormData)) {
            form = new FormData();
            for (const key in data) {
                form.append(key, data[key]);
            }
        }
        return form;
    },
    redirectHandler(response) {
        const data = response.data;

        if (data.first_redirect) {
            store.commit('auth/setFirstRedirect', data, { root: true });
        }
        if (data.rule_redirect) {
            store.commit('auth/setRuleRedirect', data, { root: true });
        }
        if (data.offer_redirect) {
            store.commit('auth/setOfferRedirect', data, { root: true });
        }
        if (data.bill_redirect) {
            store.commit('auth/setBillRedirect', data, { root: true });
        }

        if (data.first_redirect) {
            store.commit('auth/setIsNoSuccessSection', false, { root: true });
            router.push('/partner/first/');
        } else if (data.rule_redirect) {
            store.commit('auth/setIsNoSuccessSection', false, { root: true });
            router.push('/partner/entry-rules/');
        } else if (data.offer_redirect) {
            store.commit('auth/setIsNoSuccessSection', false, { root: true });
            store.dispatch('popups/open', store.state.popups.items.attentionModal, { root: true });
            router.push(`/partner/offers/${store.state.auth.user.offer_redirect}`);
        } else if (data.bill_redirect) {
            store.commit('auth/setIsNoSuccessSection', false, { root: true });
            store.dispatch('popups/open', store.state.popups.items.buhAttentionModal, { root: true });
            router.push(`/partner/buh/${store.state.auth.user.bill_redirect}/act`);
        }
    },
    errorHandler(response) {
        let error = 0;
        if (response.data || response.response) {
            error = response.response.data.data && response.response.data.data.errorCode ? response.response.data.data.errorCode : 0;
            if (!response.response.data.data?.errorCode) {
                switch (response.response.status) {
                case 401:
                    error = 405;
                    break;
                case 403:
                    error = 407;
                    break;
                case 405:
                    error = 404;
                    break;
                default:
                    error = response.response.status;
                    break;
                }
            }
        }
        const data = response.response ? response.response.data : {};
        return new Promise((resolve) => {
            switch (+error) {
            case 400:// Пользователь не существует
                store.commit('auth/setIsNoSuccess', true, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve(response);
                break;
            case 401:// Не верный токен
                store.dispatch('auth/logout', {}, { root: true });
                resolve(response);
                break;
            case 402:// Пользователь заблокирован навсегда
                store.dispatch('auth/logout', {}, { root: true });
                store.commit('auth/setBanPermanent', true, { root: true });
                store.commit('auth/setBanPermanentPhone', data.phone, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                store.commit('auth/setUser', data, { root: true });
                resolve(response);
                break;
            case 410:// Пользователь заблокирован за превышение попыток получения смс
            case 403:// Пользователь заблокирован за превышение попыток авторизации
                store.dispatch('auth/clearUserAuthInfo', {}, { root: true });
                store.commit('auth/setBan', true, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve();
                break;
            case 404:// Ресурс не найден
                resolve(response);
                router.push('/404page');
                break;
            case 405: // Токен истек
                store.dispatch('auth/checkRefreshToken', {}, { root: true }).then(() => {
                    setTimeout(() => {
                        store.commit('auth/setLoaded', true, { root: true });
                    }, 500);
                    resolve(response);
                });
                break;
            case 406:// Refresh Токен истек
                store.dispatch('auth/clearUserAuthInfo', {}, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve(response);
                break;
            case 407:// Пользователю недосупен раздел
                // store.dispatch('auth/clearUserAuthInfo', {}, {root: true});
                store.commit('auth/setIsNoSuccessSection', true, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve(response);
                break;
            case 408:// У пользователя нет доступа
                store.commit('auth/setIsNoSuccess', true, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve();
                break;
            case 409:// Не верный пароль
                resolve(response);
                break;
            default:
                resolve(response);
                store.dispatch('popups/open', store.state.popups.somethingWentWrong, { root: true });
                break;
            }
        });
    },
};
